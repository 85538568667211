import { Router } from '@angular/router';
import { Component } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { LoaderService } from '../../../core/services/loader.service';
import { deleteFaqs, getFaqs } from '../../../store/Faq/faq.actions';
import {
  delateFaqSuccess,
  deleteFaqFailure,
  getFaqFailure,
  getFaqSuccess,
} from '../../../store/Faq/faq-selector';
import { SharedService } from '../../../shared/shared.service';
import { ConfirmationModal, Faq } from '../../../shared/modals/common';
import { Roles, SweetAlertIcon } from '../../../shared/constants/enum';
import { FaqService } from '../../../core/services/faq.service';
import { PageChangeEvent } from '@progress/kendo-angular-grid';
import { table_pageSize } from '../../../shared/constants/templatesNames';

@Component({
  selector: 'app-rp-faq-view',
  templateUrl: './rp-faq-view.component.html',
  styleUrl: './rp-faq-view.component.scss',
})
export class RpFaqViewComponent {
  isFirstOpen: boolean = true;

  error$: any;
  faqsList: any = [];
  searchText: string = '';
  filteredFaqs: any = [];
  breadCrumbItems: Array<{}> | undefined;
  public pageSize = 5;
  public skip = 0;
  public pagedDestinations: [] = [];
  public contentId = 'content-1';
  public total = 1;
  currentPage: number = 1;
  public pageSizes = table_pageSize.pageSizes;
  currentUserString: any;
  roles = Roles;
  constructor(
    private router: Router,
    private store: Store,
    private loaderService: LoaderService,
    private sharedService: SharedService,
    private faqService: FaqService
  ) {
    this.breadCrumbItems = [{ label: `View Faq's` }];
    this.getStoreInfo();
  }
  role: any;
  openIndex: number | null = null;
  isOpen: any

  ngOnInit() {
    const currentUserString = localStorage.getItem('currentUser');
    if (currentUserString) {
      // Parse the data to a JavaScript object
      this.currentUserString = JSON.parse(currentUserString);
      this.role = this.currentUserString.currentUserRoleDetails.role;
      console.log(this.currentUserString.currentUserRoleDetails.role);
    }
    this.getFaqsList();
  }


  trackByIndex(index: number): number {
    return index;
  }

  /* toggleAccordion(index: number): void {
    console.log(index)
    this.openIndex = this.openIndex === index ? null : index;
  } */

  toggleAccordion(index: number): void {
    this.isOpen[index] = !this.isOpen[index]; // Toggle the state of the clicked accordion
  }


  public onPageChange(e: PageChangeEvent): void {
    this.skip = e.skip;
    this.pageSize = e.take;
    this.currentPage = Math.floor(e.skip / e.take) + 1;
    console.log(this.skip, this.pageSize, this.currentPage);
    this.getFaqsList();
  }

  getFaqsList() {
    this.store.dispatch(
      getFaqs({
        pageIndex: this.currentPage,
        pageSize: this.pageSize,
        searchTerm: this.searchText,
      })
    );

  }

  getStoreInfo() {
    this.error$ = this.store.pipe(select(getFaqFailure));
    let getFaq$ = this.store.pipe(select(getFaqSuccess));
    let delateFaqSuccess$ = this.store.pipe(select(delateFaqSuccess));
    let deleteFaqFailure$ = this.store.pipe(select(deleteFaqFailure));

    // console.log(this.sendOtpResponse$);

    getFaq$.subscribe((res: any) => {
      this.loaderService.setLoading(false);
      if (res) {
        this.faqsList = res.items;
        this.total = res.totalCount;
        this.isOpen = new Array(this.faqsList.length).fill(false);
        this.isOpen[0] = true;
        // this.updateFilteredFeedbacks();
      }
      console.log('responseprofie', res, this.faqsList);
    });

    delateFaqSuccess$.subscribe((res: any) => {
      this.loaderService.setLoading(false);

      this.getFaqsList();
      console.log('responseprofie', res);
    });

    deleteFaqFailure$.subscribe((res: any) => {
      // this.loaderService.setLoading(false);
      console.log('responseprofie', res);
    });

    this.error$.subscribe((res: any) => {
      this.loaderService.setLoading(false);
      console.log('err', res);
    });
  }

  deleteFaq(selectedFaq: any) {
    let modalInfo: ConfirmationModal = {
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: SweetAlertIcon.ERROR,
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, cancel!',
    };
    this.sharedService.showConfirmationDialog(modalInfo).then((result) => {
      console.log(result);

      if (result.isConfirmed) {
        // Perform delete operation here
        // this.deleteItem();
        this.loaderService.setLoading(true);
        this.store.dispatch(deleteFaqs({ id: selectedFaq }));
      }
      // else if (result.dismiss === Swal.DismissReason.cancel) {
      //   // Handle cancellation
      // }
    });
  }

  downloadAttachedFile(faq: any) {
    if (faq.files.length == 1) {
      this.faqService.downloadImage(faq);
      // });
      // this.faqService.downloadFile(faq.fileUrl).subscribe((blob: Blob) => {
      //   const downloadLink = document.createElement('a');
      //   downloadLink.href = window.URL.createObjectURL(blob);
      //   downloadLink.setAttribute('download', 'filename'); // Specify the filename here
      //   document.body.appendChild(downloadLink);
      //   downloadLink.click();
      //   document.body.removeChild(downloadLink);
      // });
    } else {
      this.faqService.downloadZip(faq.id);
    }
  }

  updateFaq(faq: Faq) {
    // this.router.navigate(['/faq/createFaq'], { queryParams: faq });
    this.router.navigate(['/faq/createFaq'], {
      state: { faqsdata: faq },
    });
  }

  updateFilteredFeedbacks() {
    this.filteredFaqs = this.faqsList.filter((item: any) => {
      const matchesText = this.searchText
        ? item.title.toLowerCase().includes(this.searchText.toLowerCase()) ||
        item.content.toLowerCase().includes(this.searchText.toLowerCase())
        : true;

      return matchesText;
    });
  }
  performSearch() {
    console.log("dhksa")
    this.getFaqsList();
  }

  public onValueChange(ev: string): void {
    if (ev.length == 0) {
      this.getFaqsList();
    }
  }
  clearSearch(): void {
    this.searchText = '';
    // Optionally, you can also call the search method to refresh the data
    this.performSearch();
  }
}
